//Imports.
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import imagen from "../assets/logo-MIT-PE.svg";

function Navbar({ props, type }) {
  const [dropmenu, setDropmenu] = useState(false);

  const menuRef = useRef();

  const useOnOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (!ref.current?.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };
  useOnOutsideClick(menuRef, () => {
    if (dropmenu) setDropmenu(false);
  });

  return (
    <nav className="navPadre">
        <img src={imagen} alt="imagen del logo MIT" />
        <div className="linkBox">
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            Home
          </Link>
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            Online Training
          </Link>
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            Digital Experience
          </Link>
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            DPP News
          </Link>
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            About us
          </Link>
          <Link className="link" to={""} onClick={() => setDropmenu(!dropmenu)}>
            Contact DPP
          </Link>
        </div>
    </nav>
  );
}
export default Navbar;
