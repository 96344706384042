import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import "./styles/App.scss";


function App(){
    
    return(
        <BrowserRouter>
          <Routes>
          <Route exact={true} path="/" element={<Home />} >
          </Route>

          </Routes>
        </BrowserRouter>
    )
}
export default App;