import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { useLocation } from "react-router-dom";

function ZoomButton({ props }) {
  // console.log("aaa", props.time);
  const client = ZoomMtgEmbedded.createClient();
  const search = useLocation().search
  const session = new URLSearchParams(search).get("session");
  const user = new URLSearchParams(search).get("user");
  const code = new URLSearchParams(search).get("code");
  const tk = new URLSearchParams(search).get("tk");  
  console.log(tk);
  console.log(session)
  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  var signatureEndpoint = "https://zoommitback.comunidadalumnos.org/webinar";
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  var sdkKey = "Znj4PO7vfKosHBUSOeLn8491B81GII7eibMt";
  var meetingNumber = session;
  var role = 0;
  var userName = user;
  var userEmail = "";
  var passWord = "";
  var registrantToken = tk;

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement");
    let prince = document.getElementsByClassName("principal");

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        video: {
          isResizable: false,
          popper: {
            disableDraggable: true,
            anchorElement: prince,
            placement: "top",
          },
          viewSizes: {
            default: {
              width: 650,
              height: 80,
            },
          },
        },
        chat: {
          popper: {
            disableDraggable: true,
          },
        },

        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
      },
    });

    client.join({
      sdkKey: sdkKey,
      signature: signature,
      meetingNumber: meetingNumber,
      password: passWord,
      userName: userName,
      userEmail: userEmail,
      tk: registrantToken,
    });
  }
 // hola luis
  return (
    <div className="App">
      {/* For Component View */}
      <div id="meetingSDKElement">
        {/* Zoom Meeting SDK Component View Rendered Here */}
      </div>
      {props.time === 0 || props.time < 0 ? (
        <div className="zoomButtonBox">
          <button className="zoomButton" onClick={getSignature}>
            Join the webinar
          </button>
        </div>
      ) : (
        <div className="zoomButtonBox">
          <button disabled={true} className="zoomButton"  onClick={getSignature}>
            Join the webinar
          </button>
        </div>
      )}
    </div> 
  );
}
export default ZoomButton;
