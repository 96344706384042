import React, { useEffect, useState } from "react";
import ZoomButton from "./ZoomButton";

const Clock = ({ deadline }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [time, setTime] = useState(0);
  const [click, setClick] = useState(false);

  // var time = ''
  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const aux_time = Date.parse(deadline) - Date.parse(new Date());
    setTime(aux_time)
    if (aux_time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(aux_time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((aux_time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((aux_time / 1000 / 60) % 60));
      setSeconds(Math.floor((aux_time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);
    return () => getTimeUntil(deadline);
  }, [deadline]);
  return (
    <div className="countDown" >
        
        <div className="webinar"><div ><p>Webinar Live</p></div> <span><p>Technology</p></span></div>
            <div className="timer">
                <h1 >{leading0(days)} :</h1>
                <h1 >{leading0(hours)} :</h1>
                <h1 >{leading0(minutes)} :</h1>
                <h1 >{leading0(seconds)} </h1>
            </div>
            {(time === 0 || time < 0 ) && <div className="liveText">On live</div> }

            <div className='joinButton' onClick={() => setClick(!click)}>
              <ZoomButton props={{'time' : time}} /> 
              {/* <div className='joinButton-box'>
              </div> */}
            </div>
            {click &&
              <div className="buttonsBox">
                <button>Register now </button>
                <button>Schedule a call</button>
                <button>Download the brochure</button>
              </div>
            }
    </div>
  );
};

export default Clock;
