import Navbar from "./Navbar";
import Clock from "./Clock.jsx";

function Home() {
  
  return (
    <>
      <Navbar />
      <div className="principal">
        <Clock  deadline={'march, 21, 2023, 12:00:00 UTC'}/>
      </div>
    </>
  );
}
export default Home; 
